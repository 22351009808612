<template>
    <v-card max-width="90%" class="mx-auto mt-5">
        <div v-if="post && allFields && postType">
            <!-- <v-btn color="primary" v-if="!editMode && isOwner" @click="editMode = true">ערוך מודעה</v-btn>
            <v-btn color="primary" v-if="editMode && isOwner" @click="editMode = false">בטל עריכה</v-btn> -->
            <!-- <v-divider class="py-5"></v-divider>
            <v-select v-if="isOwner" dense v-model="post.status" :items="postStatuses" label="סטטוס המודעה"
                item-text="text" item-value="value" @change="changeStatus()"></v-select> -->
            <v-card-title class="text-h5">פרטי המודעה</v-card-title>

            <div v-if="editMode">
                <v-card flat tile class="mx-auto  mt-5 pa-5" v-if="editMode" max-width="90%">

                    <v-divider class="py-5"></v-divider>
                    <div class="d-flex flex-wrap">
                        <div v-for="field in fieldsExtended_primary(getFieldsByIds(postType.postTypeExtended))"
                            :key="field.id" class="mx-7 my-0 py-0">
                            <component :is="field.type" @input="setValue(field.name, $event)"
                                :clearFormFlag="clearFormFlag" :fieldName="field.name" :fields="field.fields"
                                :result="getFieldValue(field.id)" :showOptions="field.showOptions">
                            </component>
                        </div>
                    </div>
                    <v-divider class="my-5"
                        v-if="fieldsExtended_primary(getFieldsByIds(postType.postTypeExtended)).length > 0"></v-divider>
                    <div class="d-flex flex-wrap">
                        <div v-for="field in fieldsExtended_regular(getFieldsByIds(postType.postTypeExtended))"
                            :key="field.id" class="mx-7 my-0 py-0">
                            <component :is="field.type" @input="setValue(field.name, $event)"
                                :clearFormFlag="clearFormFlag" :fieldName="field.name" :fields="field.fields"
                                :result="getFieldValue(field.id)" :showOptions="field.showOptions">
                            </component>
                        </div>
                    </div>
                    <v-divider class="my-5"
                        v-if="fieldsExtended_regular(getFieldsByIds(postType.postTypeExtended)).length > 0"></v-divider>
                    <div class="d-flex flex-wrap">
                        <div v-for="field in fieldsExtended_secondary(getFieldsByIds(postType.postTypeExtended))"
                            :key="field.id" class="mx-7 my-0 py-0">
                            <component :is="field.type" @input="setValue(field.name, $event)"
                                :clearFormFlag="clearFormFlag" :fieldName="field.name" :fields="field.fields"
                                :result="getFieldValue(field.id)" :showOptions="field.showOptions">
                            </component>
                        </div>
                    </div>
                    <v-divider class="my-5"
                        v-if="fieldsExtended_secondary(getFieldsByIds(postType.postTypeExtended)).length > 0"></v-divider>
                    <div class="d-flex flex-wrap">
                        <div v-for="field in fieldsExtended_price(getFieldsByIds(postType.postTypeExtended))"
                            :key="field.id" class="mx-7 my-0 py-0">
                            <component :is="field.type" @input="setValue(field.name, $event)"
                                :clearFormFlag="clearFormFlag" :fieldName="field.name" :fields="field.fields"
                                :result="getFieldValue(field.id)" :showOptions="field.showOptions">
                            </component>
                        </div>
                    </div>
                    <v-divider class="my-5"
                        v-if="fieldsExtended_price(getFieldsByIds(postType.postTypeExtended)).length > 0"></v-divider>
                    <v-select v-if="userContactPersons.length > 0" v-model="post.contactPerson"
                        :items="userContactPersons" item-text="name" item-value="id" label="איש קשר" return-object>
                    </v-select>
                </v-card>
            </div>
            <div v-else class="mx-9">
                <v-card flat class="py-3">
                    <div>
                        <span class="text-h6">סטטוס המודעה:</span>
                        <span>{{ getStatusText(post.status) }}</span>
                    </div>
                    <div v-for="val, key, index in post.values" :key="index">
                        <showOneField v-if="val.value"
                            :fieldValues="{ name: val.key, value: val.value, type: getFieldById(val.id) }" />
                    </div>


                </v-card>
            </div>
            <!-- <v-divider class="my-5"></v-divider> -->

            <v-card flat class="mx-9 pb-5">
                <v-divider class="my-5"></v-divider>
                <div>
                    <span class="text-h6">תאריך פרסום המודעה:</span> <span>{{ formatDateToDayView(post.created)
                        }}</span>
                </div>
                <div>
                    <span class="text-h6">תאריך השינוי האחרון:</span> <span>{{ formatDateToDayView(post.lastUpdated)
                        }}</span>
                </div>
                <div>
                    <span class="text-h6">מפרסם המודעה:</span> <span>{{ posterName
                        }}</span>
                </div>
                <div v-if="contactPersonName">
                    <span class="text-h6">
                        איש קשר:
                    </span> {{ contactPersonName }}
                </div>
                <div v-if="contactPersonPhoneNumber">
                    <span class="text-h6">
                        טלפון:
                    </span> {{ contactPersonPhoneNumber }}
                </div>
                <div>
                    <v-subheader>{{ postId }}</v-subheader>
                </div>
            </v-card>
            <v-divider class="my-5"></v-divider>
            <div>
                <v-card flat class="my-5 mx-9 pb-5">
                    <v-select class="my-9" v-if="isOwner" dense v-model="post.status" :items="postStatuses"
                        label="סטטוס המודעה" item-text="text" item-value="value" @change="changeStatus()"></v-select>
                    <v-btn color="primary" v-if="!editMode && isOwner" @click="editMode = true">ערוך מודעה</v-btn>
                    <v-btn color="red" v-if="editMode && isOwner" @click="editMode = false">בטל עריכה</v-btn>
                    <v-btn class="mx-5" color="light-green" v-if="editMode" @click="updatePost">עדכן מודעה</v-btn>
                    <span class="float-left">
                        <ProposalComponent :post="post" :UID="UID" :postId="postId" />
                    </span>

                </v-card>
            </div>

        </div>
    </v-card>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

import showOneField from "@/components/showRecord/showOneField.vue";

import PlaceInput from "@/components/addRecord/components/PlaceInput";
import NameInput from "@/components/addRecord/components/NameInput";
import DateRangeInput from "@/components/addRecord/components/DateRangeInput";
import CheckBoxInput from "@/components/addRecord/components/CheckBoxInput";
import SelectOneInput from "@/components/addRecord/components/SelectOneInput";
import SelectMultipleInput from "@/components/addRecord/components/SelectMultipleInput";
import SelectTwoLevelInput from "@/components/addRecord/components/SelectTwoLevelInput";
import NumberInput from "@/components/addRecord/components/NumberInput";

import ProposalComponent from "@/components/proposals/ProposalComponent.vue";

export default {
    name: "PostDetail",
    data() {
        return {
            post: null,
            allFields: null,
            postType: null,
            clearFormFlag: false,
            values: {},
            editMode: false,
            posterInfo: null,
            postStatuses: [
                { text: "פורסם", value: "active" },
                { text: "עבר לביצועה", value: "inProgress" },
                { text: "בוצע", value: "done" },
                { text: "ארכיון", value: "archived" }
            ],
            userContactPersons: [],
            userContactPerson: null,
            contactPersonName: "",
            contactPersonPhoneNumber: "",
        }
    },
    components: {
        showOneField,
        PlaceInput,
        NameInput,
        DateRangeInput,
        CheckBoxInput,
        SelectOneInput,
        SelectMultipleInput,
        SelectTwoLevelInput,
        NumberInput,
        ProposalComponent,
    },
    computed: {
        ...mapGetters(["user", "UID"]),
        postId() {
            console.log("this.$route.params.id", this.$route.params.id);
            return this.$route.params.id;
        },
        posterName() {
            let name = "";
            if (!this.posterInfo) return name;
            if (this.posterInfo && this.posterInfo.companyInfo && this.posterInfo.companyInfo.companyName) {
                name = this.posterInfo.companyInfo.companyName
            } else {
                if (this.posterInfo && this.posterInfo.info && this.posterInfo.info.name) {
                    name = this.posterInfo.info.name
                }
            }
            return name;
        },
        isOwner() {
            if (!this.UID) return false;
            if (!this.post || !this.post.uid) return false
            if (this.UID === this.post.uid) return true
            return false
        }
    },
    async mounted() {
        await this.getPost();
        await fire.database().ref('admin/fields/created_fields/addRecord').on('value', snapshot => {
            const rawData = snapshot.val();
            // console.log("rawData", rawData);
            this.allFields = Object.keys(rawData).map(key => {
                return {
                    ...rawData[key], // все свойства объекта
                    id: key          // добавляем id
                };
            });
            // console.log("allFields", this.allFields);
        });
        if (this.isOwner) {
            await fire.database().ref('users/' + this.UID + '/contactPersons').on('value', async (snapshot) => {
                const rawData = snapshot.val();
                this.userContactPersons = Object.keys(rawData).map(key => {
                    return {
                        id: rawData[key].existUID
                    };
                });
                this.userContactPersons.unshift({ id: this.UID });
                for (let i = 0; i < this.userContactPersons.length; i++) {
                    // console.log("this.userContactPersons[i].id", this.userContactPersons[i].id);
                    if (this.userContactPersons[i].id === this.UID) {
                        this.userContactPersons[i].name = "אני";
                        continue;
                    }
                    await fire.database().ref('users/' + this.userContactPersons[i].id + '/info/name').once('value', snapshot => {
                        console.log("snapshot.val()", snapshot.val());
                        this.userContactPersons[i].name = snapshot.val();
                    });
                }
                console.log("this.userContactPersons", this.userContactPersons);
            });
        }
        //get proposals for this post
        // await this.getProposalsForThisPost();

    },
    methods: {
        async getContactPerson() {
            let ret = "";
            if (this.post.contactPerson) {
                await fire.database().ref('users/' + this.post.contactPerson + '/info').once('value', snapshot => {
                    // this.userContactPersons[i].name = snapshot.val();
                    ret = snapshot.val();
                });
                return ret;
            } else {
                return ret;
            }
        },
        formatDateToDayView(date) {
            if (date) {
                let dateObj = new Date(date);
                //add 0 before day if it is less than 10
                let day = dateObj.getDate();
                if (day < 10) {
                    day = "0" + day;
                }
                //add 0 before month if it is less than 10
                let month = dateObj.getMonth() + 1;
                if (month < 10) {
                    month = "0" + month;
                }
                let year = dateObj.getFullYear();
                return day + "-" + month + "-" + year;
            }
            return "";
        },
        fieldsExtended_primary(allWaitsFields) {
            let fieldsExtended_primary = [];
            for (let i = 0; i < allWaitsFields.length; i++) {
                if (allWaitsFields[i].showOptions.weight === "primary") {
                    fieldsExtended_primary.push(allWaitsFields[i]);
                }
            }
            return fieldsExtended_primary;
        },
        fieldsExtended_secondary(allWaitsFields) {
            let fieldsExtended_secondary = [];
            for (let i = 0; i < allWaitsFields.length; i++) {
                if (allWaitsFields[i].showOptions.weight === "secondary") {
                    fieldsExtended_secondary.push(allWaitsFields[i]);
                }
            }
            return fieldsExtended_secondary;
        },
        fieldsExtended_price(allWaitsFields) {
            let fieldsExtended_price = [];
            for (let i = 0; i < allWaitsFields.length; i++) {
                if (allWaitsFields[i].showOptions.weight === "price") {
                    fieldsExtended_price.push(allWaitsFields[i]);
                }
            }
            return fieldsExtended_price;
        },
        fieldsExtended_regular(allWaitsFields) {
            let fieldsExtended_regular = [];
            for (let i = 0; i < allWaitsFields.length; i++) {
                if (allWaitsFields[i].showOptions.weight === "regular") {
                    fieldsExtended_regular.push(allWaitsFields[i]);
                }
            }
            return fieldsExtended_regular;
        },
        getFieldType(fieldName) {
            console.log("fieldName", fieldName);
            const field = this.allFields.find(field => field.name === fieldName.key);
            // console.log("field", field);
            // console.log("field.type", field.type);
            return field;
        },
        //get field by id
        getFieldById(fieldId) {
            const field = this.allFields.find(field => field.id === fieldId);
            // console.log("field", field);
            // console.log("field.type", field.type);
            return field;
        },
        getFieldsByIds(fieldIds) {
            // console.log("fieldIds", fieldIds);
            const fields = [];
            for (let i = 0; i < this.allFields.length; i++) {
                for (let j = 0; j < fieldIds.length; j++) {
                    if (this.allFields[i].id === fieldIds[j].id) {
                        this.allFields[i].showOptions = fieldIds[j];
                        fields.push(this.allFields[i]);
                    }
                }
            }
            // const fields = this.allFields.filter(field => fieldIds.id.includes(field.id));
            // console.log("getFieldsByIds", fields);
            return fields;
        },
        setValue(componentName, value) {
            // this.$set(this.values, componentName, value.value);
            this.$set(this.values, value.id, { id: value.id, key: componentName, value: value.value });
            console.log("componentName", componentName);
            console.log("value", value);
            console.log("this.values", this.values);
        },
        //i have here getFieldsByIds(postType.fieldIds) all the relevant fields, i need to get the values from post.values by the field.name
        getFieldValue(id) {
            if (!this.post.values[id]) {
                return;
            }
            const fieldValue = this.post.values[id].value;
            return fieldValue;
        },
        async updatePost() {
            console.log("values", this.values);
            if (!this.values || Object.keys(this.values).length === 0) {
                alert("Please fill all fields");
                return;
            }
            console.log("write to db...");
            await fire.database().ref("admin/posts/" + this.postId)
                .update({
                    values: this.values,
                    lastUpdated: new Date().toISOString(),
                    status: this.post.status,
                    contactPerson: this.post.contactPerson ? this.post.contactPerson.id : null,
                });
            // this.clearForm();
            this.editMode = false;
        },
        async getPost() {
            let viewMessage = this;
            let itemRef = await fire.database().ref('admin/posts/' + this.postId);
            await itemRef.on('value', async (snapshot) => {
                let items = snapshot.val();
                if (items) {
                    // console.log("this.post", items);
                    viewMessage.post = JSON.parse(JSON.stringify(items));
                    let contactPerson_tmp = await viewMessage.getContactPerson();
                    if(contactPerson_tmp) {
                        viewMessage.contactPersonName = contactPerson_tmp.name;
                        viewMessage.contactPersonPhoneNumber = contactPerson_tmp.phoneNumber;
                    }
                }
            });
        },
        getStatusText(status) {
            let statusText = "";
            for (let i = 0; i < this.postStatuses.length; i++) {
                if (this.postStatuses[i].value === status) {
                    statusText = this.postStatuses[i].text;
                }
            }
            return statusText;
        },
        changeStatus() {
            console.log("this.post.status", this.post.status);
            fire.database().ref("admin/posts/" + this.postId)
                .update({
                    status: this.post.status,
                });
        },
    },
    watch: {
        post() {
            let viewMessage = this;
            console.log(this.post)
            fire.database().ref('admin/postTypes/' + viewMessage.post.postTypeId).on('value', snapshot => {
                const rawData = snapshot.val();
                this.postType = rawData;
                // console.log("postType", this.postType);
            });
            //get user that posted the record from users.${this.post.uid} from firebase and put it to this.posterInfo
            if (viewMessage.post.uid && viewMessage.post.uid != '') {
                fire.database().ref('users/' + viewMessage.post.uid).on('value', snapshot => {
                    const rawData = snapshot.val();
                    viewMessage.posterInfo = rawData;
                    // console.log("viewMessage.posterInfo", viewMessage.posterInfo)
                });
            }
        }
    }
}
</script>
